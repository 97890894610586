.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 1rem;
  min-height: 100vh;
  width: 100%;
  background-image: url("../../../assets/images/background-blue.png"),
    linear-gradient(rgba(0, 33, 122, 0.563), rgb(55, 164, 219)),
    url("../../../assets/images/background-tech.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  overflow-x: hidden;
}

h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.portfolio .carousel-box img {
  width: 100%;
  max-width: 500px;
  height: "auto";
  border-radius: 10px;
}

.portfolio .carousel-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgb(55, 164, 219);
  padding: 50px;
}

.portfolio .carousel-box a {
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 892px) {
  .portfolio .carousel-box img {
    width: 70%;
  }

  h1 {
    font-size: 2rem;
  }
}
