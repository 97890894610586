.call-to-action {
  margin: 100px auto;
  width: 80%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../../assets/images/test-banner2.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  text-align: center;
  padding: 100px 0;
}

.call-to-action h1 {
  color: #fff;
  margin-bottom: 40px;
}

/* Footer */
.footer {
  width: 100%;
  text-align: center;
  padding: 30px 0;
}

.footer p {
  color: #777;
  font-size: 14px;
  font-weight: 300;
}

footer h4 {
  margin-bottom: 25px;
  margin-top: 20px;
  font-weight: 600;
}

.icons .fa-brands {
  color: #37a4db;
  margin: 0 13px;
  cursor: pointer;
  padding: 18px 0;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.qr-code {
  width: 150px;
  height: 150px;
  margin-top: 5px;
  border: #37a4db 2px solid;
  background-color: #ffffff;
}
