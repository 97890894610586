.whatsapp-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #25d366;
    color: #fff;
    font-size: 36px;
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    -webkit-transition: 0.8s ease-out;
    transition: 0.8s ease-out;
    overflow: hidden;
}