body {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

/* Header */
.header {
    min-height: 100vh;
    width: 100%;
    background-image:
        url("../src/assets/images/background-blue.png"),
        linear-gradient(rgba(0, 33, 122, 0.563), rgb(55, 164, 219)), url("../src/assets/images/background-tech.jpg");
    background-position: center;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
}

.header-box {
    text-align: center;
    color: hsl(204, 100%, 20%);
}

.header-box img {
    padding-top: 100px;
    padding-bottom: 20px;
    width: 300px;
    margin: auto;
}

.header-box h1 {
    font-size: 25px;
    display: table;
    margin: auto;
}

.header-box h1::after {
    content: "";
    width: 110%;
    height: 2px;
    background: #37a4db;
    display: block;
    margin: auto;
    margin-left: -5%;
    margin-top: 3px;
    border-radius: 25px;
}

.header-box p {
    margin: auto;
    font-size: 18px;
    padding-top: 10px 0 0 0;
    color: hsl(204, 100%, 20%);
    font-weight: normal;
    line-height: normal;
}

.header-box p strong {
    color: #006db8;
}

.header-button,
.contact-button {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 2px solid hsl(204, 100%, 20%);
    border-radius: 25px;
    padding: 12px 34px;
    font-size: 18px;
    background-color: #006db8;
    position: relative;
    cursor: pointer;
    margin: auto;
    margin-top: 16px;
}

.header-button:hover,
.contact-button:hover {
    border: 2px solid #37a4db;
    border-radius: 25px;
    background-color: #37a4db;
    transition: 1s;
}

#description {
    margin-top: 0;
    margin-bottom: 40px;
}

/* Menu */
nav {
    display: flex;
    padding: 10px 10px;
    justify-content: space-between;
    align-items: center;
    background-color: #006db8;
}

nav img {
    width: 100px;
    border: 2px solid #37a4db;
    border-radius: 25px;
    background-color: #c1edff;
    padding: 3px 19px 4px 24px;
    margin-left: 130px;
}

.nav-links {
    flex: 1;
    text-align: right;
    margin-right: 130px;
}

.nav-links ul li {
    list-style: none;
    display: inline-block;
    padding: 8px 12px;
    position: relative;
}

.nav-links ul li a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
}

.nav-links ul li::after {
    content: "";
    width: 0%;
    height: 2px;
    background: #37a4db;
    display: block;
    margin: auto;
    transition: 0.5s;
}

.nav-links ul li:hover::after {
    width: 100%;
}

nav .fa-solid {
    display: none;
}

/* About */
.about {
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 50px;
}

.about p:nth-of-type(2) {
    padding-bottom: 50px;
}

.about-column {
    flex-basis: 32%;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
}

.about-column img {
    width: 100%;
    display: block;
}

.layer {
    background: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
}

.layer:hover {
    background: rgba(55, 164, 219, 0.7);
}

.layer h3 {
    width: 100%;
    font-weight: 500;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    left: 50%;
    text-transform: uppercase;
    transform: translateX(-50%);
    position: absolute;
    opacity: 0;
    transition: 0.5s;
}

.layer:hover h3 {
    bottom: 49%;
    opacity: 1;
}

/* Services */
.services {
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 100px;
}

h1 {
    font-size: 36px;
    font-weight: 600;
}

p {
    color: hsl(204, 100%, 20%);
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
}

.row {
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
}

.services-column {
    flex-basis: 31%;
    background: #ebfaff;
    ;
    border-radius: 10px;
    margin-bottom: 5%;
    padding: 20px 12px;
    transition: 0.5s;
}

h3 {
    text-align: center;
    font-weight: 600;
    margin: 10px 0;
}

.services-column:hover {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
}

.itens-list {
    text-align: left;
    padding: 0;
    margin-left: 30px;
}

/* Partners */
.partners {
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 100px;
}

.partners-column {
    flex-basis: 31%;
    border-radius: 10px;
    margin-bottom: 5%;
    text-align: left;
}

.partners img {
    width: 100%;
    border-radius: 10px;
}

.partners-column p {
    padding: 0;
}

.partners-column h3 {
    margin-top: 16px;
    margin-bottom: 15px;
    text-align: left;
}

/* Testimonials */

.testimonials {
    width: 80%;
    margin: auto;
    padding-top: 100px;
    text-align: center;
}

.testimonial-column {
    flex-basis: 44%;
    border-radius: 10px;
    margin-bottom: 5%;
    text-align: left;
    background: #ebfaff;
    padding: 25px;
    cursor: pointer;
    display: flex;
}

.testimonial-column img {
    height: 40px;
    margin-left: 5px;
    margin-right: 30px;
    border-radius: 50%;
}

.testimonial-column p {
    padding: 0;
}

.testimonial-column h3 {
    margin-top: 15px;
    text-align: left;
}

.testimonial-column .fa-solid,
.testimonial-column .fa-regular {
    color: #f44336;
}

/* Call to action */
.call-to-action {
    margin: 100px auto;
    width: 80%;
    background-image:
        linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/images/test-banner2.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    text-align: center;
    padding: 100px 0;
}

.call-to-action h1 {
    color: #fff;
    margin-bottom: 40px;
}

/* Footer */
.footer {
    width: 100%;
    text-align: center;
    padding: 30px 0;
}

.footer p {
    color: #777;
    font-size: 14px;
    font-weight: 300;
}

footer h4 {
    margin-bottom: 25px;
    margin-top: 20px;
    font-weight: 600;
}

.icons .fa-brands {
    color: #37a4db;
    margin: 0 13px;
    cursor: pointer;
    padding: 18px 0;
}

/* Styling to Small devices */
@media(max-width: 892px) {
    .header-box h1 {
        font-size: 20px;
    }

    .nav-links ul li {
        display: block;
    }

    .nav-links {
        position: fixed;
        background: #37a4db;
        height: 100vh;
        width: 200px;
        top: 0;
        right: -200px;
        text-align: left;
        z-index: 2;
        margin-right: 0px;
        transition: 1s;
    }

    nav .fa-solid {
        display: block;
        color: hsl(204, 100%, 20%);
        margin: 10px;
        font-size: 22px;
        cursor: pointer;
    }

    .nav-links ul {
        height: 100%;
        padding: 30px;
    }

    .nav-links ul li a {
        color: hsl(204, 100%, 20%);
    }

    .header-box {
        padding: 50px;
    }

    .header-box img {
        width: 250px;
        /* border: 2px solid red; */
        padding-top: 0px;
    }

    .row {
        flex-direction: column;
    }

    .testimonial-column img {
        margin-left: 0;
        margin-right: 15px;
    }

    .call-to-action h1 {
        font-size: 24px;
    }
}