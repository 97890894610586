.services {
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 100px;
    height: 100vh;
}

.services-column {
    flex-basis: 31%;
    background: #ebfaff;
    ;
    border-radius: 10px;
    margin-bottom: 5%;
    padding: 20px 12px;
    transition: 0.5s;
}

.services-column:hover {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
}

.itens-list {
    text-align: left;
    padding: 0;
    margin-left: 30px;
}